import React, { useState, useEffect } from "react";
import axios from "axios";
import "./ManageNews.css";  // Import CSS for styling
import { useNavigate } from "react-router-dom";
import { API_URL } from './config';  //  ${API_URL}

const ManageNews = () => {
  const [newsList, setNewsList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  

  useEffect(() => {
    // Fetch all news articles from the backend
    const fetchNews = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/news`);
        // console.log("Fetched news:", response.data.news);  // Log the data to check
        setNewsList(response.data.news);  // Extract the 'news' array from the response
      } catch (error) {
        console.error("Error fetching news", error);
        setErrorMessage("Failed to fetch news.");
      }
    };

    fetchNews();
  }, []);

  // Handle delete news
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this news?")) {
      try {
        const response = await axios.delete(`${API_URL}/api/news/${id}`);
        // console.log(response.data);  // Log the response to check for errors
        setNewsList(newsList.filter((news) => news._id !== id));  // Update state to remove deleted news
      } catch (error) {
        console.error("Error deleting news:", error);
        setErrorMessage("Failed to delete news.");
      }
    }
  };

  return (
    <div className="manage-news-container">
      <h1 className="manage-news-title">Manage News</h1>

      {errorMessage && <p className="error-message">{errorMessage}</p>}

      <table className="news-table">
        <thead>
          <tr>
            <th>Title</th>
            <th>Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {newsList.length > 0 ? (
            newsList.map((news) => (
              <tr key={news._id}>
                <td>{news.title}</td>
                {/* <td>{news._id}</td> */}
                
                <td>{new Date(news.date).toLocaleDateString()}</td>
                <td>
                  <button
                    className="edit-btn"
                    onClick={() => {
                      // console.log("Navigating to edit-news with ID:", news._id);  // Debugging line
                      navigate(`/edit-news/${news._id}`);  // Use React Router's navigate function
        }}
                  >
                    Edit
                  </button>
                  <button
                    className="delete-btn"
                    onClick={() => handleDelete(news._id)}  // Use handleDelete function
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3" className="no-news">
                No news articles available.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ManageNews;
