import React, { useContext } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Header from "./components/Header";
import MainPage from "./components/MainPage";
import AdminDashboard from "./components/AdminDashboard";
import NewsForm from "./components/NewsForm";
import ManageNews from "./components/ManageNews";
import EditNews from "./components/EditNews";  // Import the EditNews component
import Login from "./components/Login";
import AboutUs from "./components/AboutUs";
import { AuthContext } from "./context/AuthContext";  // Import AuthContext
import "./App.css";
import UserManagement from "./components/UserManagement";
import EditUser from './components/EditUser';  // Import the EditUser component

const App = () => {
  const { isAuthenticated, role } = useContext(AuthContext);

  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/login" element={<Login />} />
        {isAuthenticated && role === "admin" && (
          <>
            <Route path="/admin" element={<AdminDashboard />} />
            <Route path="/add-news" element={<NewsForm />} />
            <Route path="/news-management" element={<ManageNews />} />
            <Route path="/edit-news/:id" element={<EditNews />} />  {/* Edit news */}
            <Route path="/user-management" element={<UserManagement />} /> 
            <Route path="/edit-user/:id" element={<EditUser />} />  {/* Define the edit-user route */}
          </>
        )}
        {!isAuthenticated && <Route path="*" element={<Navigate to="/login" />} />}
      </Routes>
    </Router>
  );
};

export default App;
