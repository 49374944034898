import React, { useState } from "react";
import axios from "axios";
import "./NewsForm.css";  // Import CSS file for styling
import { API_URL } from './config';  //  ${API_URL}

const NewsForm = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_URL}/api/news/add`, {
        title,
        content,
      });
      setSuccessMessage("News added successfully!");
      setTitle("");  // Clear form fields
      setContent("");
    } catch (error) {
      setErrorMessage("Failed to add news. Please try again.");
      console.error(error);
    }
  };

  return (
    <div className="news-form-container">
      <h1 className="news-form-title">Add News</h1>
      <form className="news-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="title">Title</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="content">Content</label>
          <textarea
            id="content"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            required
          ></textarea>
        </div>

        <button type="submit" className="news-form-btn">Submit</button>

        {successMessage && <p className="success-message">{successMessage}</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </form>
    </div>
  );
};

export default NewsForm;
