import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import "./NewsForm.css";  // Reuse the same styles as the Add News page
import { API_URL } from './config';  //  ${API_URL}

const EditNews = () => {
  const { id } = useParams();  // Get the news ID from the URL
  const navigate = useNavigate();  // For redirecting after successful update
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    // Fetch the news by ID to pre-fill the form
    const fetchNews = async () => {
      try {
        const token = localStorage.getItem("token");  // Get the token from localStorage
        const response = await axios.get(`${API_URL}/api/news/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,  // Pass the token in the Authorization header
          },
        });
        const newsData = response.data;
        setTitle(newsData.title);
        setContent(newsData.content);
      } catch (error) {
        setErrorMessage("Failed to load news article.");
        console.error(error);
      }
    };

    fetchNews();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");  // Get the token from localStorage
      await axios.put(
        `${API_URL}/api/news/${id}`,
        {
          title,
          content,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,  // Pass the token in the Authorization header
          },
        }
      );
      setSuccessMessage("News updated successfully!");
      setTimeout(() => navigate("/news-management"), 2000);  // Redirect after 2 seconds
    } catch (error) {
      setErrorMessage("Failed to update news. Please try again.");
      console.error(error);
    }
  };

  return (
    <div className="news-form-container">
      <h1 className="news-form-title">Edit News</h1>
      <form className="news-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="title">Title</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="content">Content</label>
          <textarea
            id="content"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            required
          ></textarea>
        </div>

        <button type="submit" className="news-form-btn">Update</button>

        {successMessage && <p className="success-message">{successMessage}</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </form>
    </div>
  );
};

export default EditNews;
