import React, { createContext, useState, useEffect } from "react";
import {jwtDecode} from "jwt-decode";  // Use default import

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState(null);

  useEffect(() => {
    // Check if there's a token in localStorage when the app loads
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setIsAuthenticated(true);
        setRole(decodedToken.user.role);  // Assuming your JWT contains a user object with a role
      } catch (error) {
        console.error("Invalid token", error);
        localStorage.removeItem("token");  // Clear invalid token
      }
    }
  }, []);
  

  const handleLogin = (token) => {
    localStorage.setItem("token", token);
    const decodedToken = jwtDecode(token);
    setIsAuthenticated(true);
    setRole(decodedToken.user.role);  // Ensure you're checking the right field
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    setRole(null);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, role, handleLogin, handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
