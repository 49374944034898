import React, { useEffect, useState } from "react";
import { API_URL } from './config';  // API base URL from config
import { useNavigate } from 'react-router-dom';  // Replaced useHistory with useNavigate
import "./UserManagement.css";  // Optional: For styling

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Hook for programmatic navigation

  // Fetch users from API on component mount
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${API_URL}/api/users`);  // Correct API route
        if (!response.ok) {
          throw new Error("Error fetching users.");
        }
        const data = await response.json();
        setUsers(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchUsers();
  }, []);

  const handleDelete = async (userId) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      try {
        const response = await fetch(`${API_URL}/api/users/${userId}`, {
          method: "DELETE",
        });
        if (!response.ok) {
          throw new Error("Error deleting user.");
        }
        // Update the UI after successful deletion
        setUsers(users.filter((user) => user._id !== userId));  // Assuming MongoDB, so using _id
      } catch (err) {
        setError(err.message);
      }
    }
  };

  const handleEdit = (userId) => {
    // Navigate to edit user page or open a modal
    navigate(`/edit-user/${userId}`);  // Replaced history.push with navigate
  };

  return (
    <div className="user-management-container">
      <h1>Manage Users</h1>
      {loading ? (
        <p>Loading users...</p>
      ) : error ? (
        <p className="error">{error}</p>
      ) : (
        <table className="user-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.length > 0 ? (
              users.map((user) => (
                <tr key={user._id}>
                  <td>{user._id}</td><td>{user.name}</td><td>{user.email}</td><td>{user.role}</td>
                  <td>
                    <button className="edit-btn" onClick={() => handleEdit(user._id)}>Edit</button>
                    <button className="delete-btn" onClick={() => handleDelete(user._id)}>Delete</button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">No users found</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default UserManagement;
