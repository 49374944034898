import React from "react";
import "./AboutUs.css";  // Import the CSS file

const AboutUs = () => {
  return (
    <div className="about-container">
      <div className="about-header">
        <h1>About Goondocks Inc.</h1>
        <p>Exploring the boundaries of human connection and technology.</p>
      </div>

      <section className="about-content">
        <div className="about-description">
          <h2>Our Mission</h2>
          <p>
          At Goondocks Inc., we focus on reimagining and applying cutting-edge AI solutions to enhance social engagement. 
          Our mission is to create technologies that foster meaningful connections, facilitate dynamic interactions, 
          and enrich the social experiences of both individuals and communities. 
          By leveraging advanced machine learning algorithms and data analytics, we build platforms that respond to human needs, 
          driving deeper engagement and transforming the way people connect in today’s digital world.
          </p>
        </div>

        <div className="about-team">
          <h2>Meet the Team</h2>
          <div className="team-grid">
            <div className="team-member">
              {/* <img src="/path/to/image1.jpg" alt="Troy" /> */}
              <h3>Troy</h3>
              <p>Founder & CEO</p>
            </div>
            
            {/* Add more team members as needed */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
