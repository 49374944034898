import React, { useEffect, useState } from "react";
import axios from "axios";
import DOMPurify from "dompurify";
import "./NewsList.css";
import { API_URL } from './config';  //  ${API_URL}


const NewsList = () => {
  const [news, setNews] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  // Fetch news from the backend
  const fetchNews = async (page) => {
    setLoading(true);
    try {
      const res = await axios.get(`${API_URL}/api/news?page=${page}`);
      if (res.data.news.length > 0) {
        setNews((prevNews) => {
          const newNews = [...prevNews, ...res.data.news];
          const uniqueNews = newNews.filter(
            (item, index, self) => index === self.findIndex((t) => t._id === item._id)
          );
          return uniqueNews;
        });
      } else {
        setHasMore(false);
      }
    } catch (err) {
      console.error("Error fetching news", err);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchNews(page);
  }, [page]);

  // Function to load more news
  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  // Function to format plain text into HTML with <p> and <br> tags
  const formatPlainText = (text) => {
    const paragraphs = text
      .trim() // Remove leading/trailing whitespace
      .split(/\n\s*\n/) // Split by double new lines (preserves blank lines)
      .map((para) => `<p>${para.trim().replace(/\n/g, "<br>")}</p>`); // Replace single line breaks with <br> inside <p> tags
  
    return DOMPurify.sanitize(paragraphs.join("")); // Sanitize and return HTML string
  };

  return (
    <div className="news-list">
      {news.length > 0 ? (
        news.map((item) => (
          <div key={item._id} className="news-item">
            <h3>{item.title}</h3>
            {/* Render formatted plain text as HTML */}
            <div
              className="news-content"
              dangerouslySetInnerHTML={{ __html: formatPlainText(item.content) }}  // Format and render plain text as HTML
            />
            <small>{new Date(item.date).toLocaleDateString()}</small>
          </div>
        ))
      ) : (
        <p>No news available.</p>
      )}

      {loading && <p>Loading...</p>}

      {!loading && hasMore && (
        <button onClick={loadMore} className="load-more-btn">
          View More
        </button>
      )}

      {!hasMore && !loading && <p>No more news to load.</p>}
    </div>
  );
};

export default NewsList;
