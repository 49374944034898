import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { API_URL } from './config';  // Use the API URL from config

const EditUser = () => {
  const { id } = useParams();  // Get the user ID from the URL
  const [user, setUser] = useState({});
  const [password, setPassword] = useState('');  // For handling password change
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();  // For navigation

  // Fetch user details based on the ID
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch(`${API_URL}/api/users/${id}`);
        if (!response.ok) {
          throw new Error("Error fetching user data.");
        }
        const data = await response.json();
        setUser(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    
    fetchUser();
  }, [id]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Prepare the updated data to send to the server
    const updatedUser = {
      name: user.name,
      email: user.email,
      role: user.role,
      password: password || undefined  // Only include the password if it's been changed
    };

    try {
      const response = await fetch(`${API_URL}/api/users/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(updatedUser),
      });
      if (!response.ok) {
        throw new Error("Error updating user.");
      }
      alert("User updated successfully!");
      navigate('/user-management');  // Navigate back to the user management page
    } catch (err) {
      setError(err.message);
    }
  };

  const handleChange = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="error">{error}</p>;

  return (
    <div>
      <h1>Edit User</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input type="text" name="name" value={user.name || ''} onChange={handleChange} />
        </label>
        <label>
          Email:
          <input type="email" name="email" value={user.email || ''} onChange={handleChange} />
        </label>
        <label>
          Role:
          <input type="text" name="role" value={user.role || ''} onChange={handleChange} />
        </label>
        <label>
          Password:  {/* Add field for password update */}
          <input type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </label>
        <button type="submit">Save Changes</button>
      </form>
    </div>
  );
};

export default EditUser;
