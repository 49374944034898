import React, { useState } from "react";
import NewsForm from "./NewsForm";
import NewsList from "./NewsList";
import ChatBot from "./ChatBot";
import "./MainPage.css"; // Custom styles for MainPage

const MainPage = ({ isLoggedIn }) => {
  const [showNewsForm, setShowNewsForm] = useState(false);

  return (
    <div className="main-page">
      {/* Floating ChatBot Card */}
      <div className="main-page-left">
        <div className="floating-card">
          <ChatBot />
        </div>
      </div>

      {/* Right side - Floating News Cards */}
      <div className="main-page-right">
        {isLoggedIn && (
          <button
            className="toggle-news-btn"
            onClick={() => setShowNewsForm(!showNewsForm)}
          >
            {showNewsForm ? "Show News List" : "Add News"}
          </button>
        )}
        {showNewsForm ? (
          <NewsForm onNewsAdded={() => setShowNewsForm(false)} />
        ) : (
          <div className="news-cards-container">
            {/* Render NewsList here */}
            <NewsList />
          </div>
        )}
      </div>
    </div>
  );
};

export default MainPage;
