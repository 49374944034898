import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import "./Header.css";
import logo from "../assets/logo.png";

const Header = () => {
  const { isAuthenticated, role, handleLogout } = useContext(AuthContext);

  return (
    <header className="header">
      <div className="logo">
        <Link to="/">
          <img src={logo} alt="Goondocks Inc." />
        </Link>
      </div>
      <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About Us</Link>
          </li>
          {isAuthenticated && role === "admin" && (  // Only show for admin users
            <li>
              <Link to="/admin">Admin Dashboard</Link>
            </li>
          )}
          {isAuthenticated ? (
            <>
              <li>
                <button onClick={handleLogout}>Sign Out</button>
              </li>
            </>
          ) : (
            <li>
              <Link to="/login">Sign In</Link>
            </li>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
