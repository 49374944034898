import React, { useState } from "react";
import axios from "axios";
import "./ChatBot.css";
import chatBotImage from "../assets/chat1.jpg";
import { API_URL } from './config';  //  ${API_URL}

const ChatBot = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");

  const handleSend = async () => {
    if (input.trim()) {
      // Add user message locally
      const userMessage = { text: input, fromUser: true };
      setMessages([...messages, userMessage]);
      setInput("");

      // Log the user message to MongoDB
      try {
        await axios.post(`${API_URL}/api/messages/log`, userMessage);
        // console.log("User message logged to MongoDB");
      } catch (error) {
        console.error("Failed to log user message", error);
      }

      // Simulate a bot response without logging it
      setTimeout(() => {
        const botMessage = { text: "Hello, I am currently under development, and hope to be online soon.", fromUser: false };
        setMessages((prevMessages) => [...prevMessages, botMessage]);
      }, 1000);  // 1 second delay for bot response
    }
  };

  return (
    <div className="chat-bot">
      <div className="chat-bot-image">
        <img src={chatBotImage} alt="Chat Bot" />
      </div>

      <div className="chat-bot-conversation">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`chat-bot-message ${message.fromUser ? "user-message" : "bot-message"}`}
          >
            {message.text}
          </div>
        ))}
      </div>

      <div className="chat-bot-input">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Ask me anything..."
        />
        <button onClick={handleSend}>Send</button>
      </div>
    </div>
  );
};

export default ChatBot;
