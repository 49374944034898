import React from "react";
import { Link } from "react-router-dom";
import "./AdminDashboard.css";  // Import the CSS file for styling

const AdminDashboard = () => {
  return (
    <div className="admin-dashboard-container">
      <h1 className="dashboard-title">Admin Dashboard</h1>
      <p className="dashboard-subtitle">Welcome, Admin! Manage your site from here.</p>

      <div className="dashboard-actions">
        <div className="dashboard-card">
          <h2>Manage News</h2>
          <p>View, edit, or delete news articles.</p>
          <Link to="/news-management" style={{ textDecoration: 'none' }}>
            <button className="dashboard-btn">Manage News</button>
          </Link>
        </div>

        <div className="dashboard-card">
          <h2>Add News</h2>
          <p>Create new news articles for your platform.</p>
          <Link to="/add-news" style={{ textDecoration: 'none' }}>
            <button className="dashboard-btn">Add News</button>
          </Link>
        </div>

        <div className="dashboard-card">
          <h2>Manage Users</h2>
          <p>View and manage users on your platform.</p>
          <Link to="/user-management" style={{ textDecoration: 'none' }}>
            <button className="dashboard-btn">Manage Users</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
